import React from 'react';
import P150ErrorPage from '~/templates/pages/components/P150ErrorPage';
import { P150ErrorPage as P150ErrorPageType } from '~/lib/data-contract';
import { NodeLocalStorage } from '~/lib/storage';
import { getInitialLoadExtraContent, pageResolver } from '~/templates/pages/utils';
// import { pageResolver } from '~/templates/pages/utils';

type NotFoundPageProps = {
    page: P150ErrorPageType;
};

/**
 * We rely on the CMS to provide us with content for the 500 page.
 */
export const ErrorPage = ({ page }: NotFoundPageProps) => {
    return page ? <P150ErrorPage {...page} /> : <></>;
};

export const getStaticProps = async () => {
    const { API_URL } = process.env;
    try {
        if (!API_URL) throw new Error('API_URL is not defined in env variables.');

        const storage = new NodeLocalStorage(
            new (require('node-localstorage').LocalStorage)('./scratch'),
        );
        const initialData = await getInitialLoadExtraContent(storage, true, { url: '/' });
        const notFoundPage = initialData.frame.settings?.pageReferences.serverErrorPage;

        const { data: page } = await pageResolver({
            url: notFoundPage?.url ?? '/500',
        });

        return {
            props: {
                page,
                frame: initialData.frame,
                translations: initialData.translations,
            },
            revalidate: 10,
        };
    } catch (e) {
        return {
            props: {
                page: { market: 'dk' },
            },
            revalidate: 1,
        };
    }
};

export default ErrorPage;
